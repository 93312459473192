import Head from "next/head";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import { Header, Footer } from "../components";

import {
  Block01,
  Block02,
  Block03,
  Block09,
  Block05,
  Block04,
  Block06,
  Block07,
  Block08,
} from "../blocks";

export default function Home() {
  const router = useRouter();
  const { locale = "pt" } = router;

  const mockLocale = [
    {
      locale: "pt",
      title: "2ND Market - Compre, venda e troque criptografia facilmente",
      description:
        "Controle seus ativos e criptomoedas, interagindo em um ambiente totalmente descentralizado e fácil de negociar. Faça seu pré-cadastro!",
    },
    {
      locale: "en",
      title: "2ND Market - Buy, sell and trade crypto easily",
      description:
        "Control your assets and cryptocurrencies, interacting in a fully decentralized and easy to trade environment. Make your pre-registration!",
    },
  ];

  let metaTag;
  mockLocale.map((item) => {
    if (item.locale === locale) {
      metaTag = {
        title: item.title,
        locale: item.locale,
        description: item.description,
      };
    }
  });

  return (
    <>
      <Head>
        <title>{metaTag.title}</title>
        <meta property="og:title" content={metaTag.title} />
        <meta name="description" content={metaTag.description} />
        <meta property="og:description" content={metaTag.description} />
        <meta property="og:site_name" content="2ND Market" />
        <meta property="og:url" content={ProductUrls.SECOND_MARKET} />
        <meta property="og:image" content="/img/banner.jpg" />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <Block01 />
      <Block02 />
      <Block03 />
      <Block09 />
      <Block05 />
      <Block04 />
      <Block06 />
      <Block07 />
      <Block08 />
      <Footer />
    </>
  );
}
